import React, { useState, useEffect } from "react";
import { ImageUrl } from "../../utils/constants";
import PropTypes from "prop-types";
import { RecommendedContainer } from "./styles";
import LazyImage from "../common/LazyImage/LazyImage";
import { ChallengeHeader } from "../ChallengeDashboardV2/styles";
import SkeletonLoder from "../common/skeletonLoder";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import parse from 'react-html-parser';

const RecommendedEvents = ({
  RecommendedEventData,
  margin,
  isActivity,
  history,
  RecommendedEventDataLoading,
  themesHeader,
  heading,
  isCoachEvent,
  marginB,
  buttonName,
  isQuizCard
}) => {
  const [activeRecommendation, setActiveRecommendation] = useState(
    RecommendedEventData[0]
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [isInside/*,setIsInside*/] = useState(false);
  const [bannerData, setBannerData] = useState(RecommendedEventData);

  useEffect(() => {
    setActiveRecommendation(RecommendedEventData[0]);
    setBannerData(RecommendedEventData);

    const interval = window.setInterval(() => {
      if (!isInside && bannerData.length) {
        setActiveIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % bannerData.length;
          setActiveRecommendation(bannerData[newIndex]);
          return newIndex;
        });
      }
    }, 88000);

    return () => window.clearInterval(interval);
  }, [isInside, bannerData]);


  const onChallengeWellnessRedirection = (id) => {
    history.push({pathname:`/company/events/create-lunch-learns`,state:{isOnDemandEvent:true,eventId:id}});
  };

  const onDemandEventRedirection = (id, category) => {
    history.push(`/events/on-demand/${category}/${id}`);
  };

  // const onQuizRedirection = () => {
  //   history.push(`/company/wellness/create-quiz`);
  // };

  // const onQuizRedirection = () => {
  //   history.push(`/company/wellness/create-quiz`);
  // };

  const onQuizRedirection = (ids,image) => {
    history.push({pathname:`/company/wellness/create-quiz`,state:{ids:ids,image:image}});
  };

  return (
    <React.Fragment>
      <ChallengeHeader
        style={{ color: "#005c87", lineHeight: "24px", marginTop: margin }}
      >
        {heading?heading:`Recommended Lunch and Learns: ${themesHeader.month}’s ${themesHeader.theme_text}`}
      </ChallengeHeader>

      {RecommendedEventDataLoading ? (
        <React.Fragment>
          {" "}
          <SkeletonLoder
            width={"1248px"}
            height={"450px"}
          ></SkeletonLoder>
        </React.Fragment>
      ) : (
        <RecommendedContainer
          marginB={marginB}
          background={isQuizCard?activeRecommendation.image:
            activeRecommendation?.thumbnail_image ? activeRecommendation?.thumbnail_image : "white"
          }
          width={isQuizCard}
        >
          <div
            className="row"
            // onMouseEnter={() => setIsInside(true)}
            // onMouseLeave={() => setIsInside(true)}
          >
            <div className="col-1">
              <div className="details">
                <div className="title">
                  <span className="title-text">
                    {isQuizCard?"Launch Quiz: Based on the following articles": isActivity
                      ? activeRecommendation.title
                      : activeRecommendation.title}
                  </span>
                </div>
                {!isQuizCard&&<div className="description" >{parse(activeRecommendation?.description)} </div>}
                {<div className="titleWrapper">
                  {activeRecommendation?.titles?.map((data,index)=>
                    <div key={index}>
                      <span className="bullet">•</span>{data}
                    </div>)}
                </div>}
                <div className="moving-btn">
                  <button
                    onClick={()=>{
                      isQuizCard?onQuizRedirection(activeRecommendation?.ids, activeRecommendation?.image):
                        isCoachEvent?
                          onDemandEventRedirection(activeRecommendation.id, activeRecommendation?.category_name):
                          onChallengeWellnessRedirection(
                            activeRecommendation.id
                          )

                    }
                    }
                  >
                    <img src={ImageUrl+"/event-section/rocket.svg"} style={{margin:"0 12px 0 0px"}}/>
                    {buttonName?buttonName:isQuizCard?"Launch the Quiz":isCoachEvent?"Launch Event":"Start a Lunch and Learn"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="imageContainer">
                <div className="imageWrapper">
                  {RecommendedEventData.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveRecommendation(item);
                        setActiveIndex(index);
                      }}
                      style={{ marginLeft: "15px",cursor:"pointer" }}
                    >
                      <LazyImage
                        style={{
                          width: isQuizCard ? "128px" :"120px",
                          height:isQuizCard ? "80px": "70px",
                          borderRadius: "6px",
                          border: activeIndex === index && "2px solid white",
                          boxShadow: "0px 8px 25px 0px rgba(0, 92, 135, 0.20)",
                        }}
                        src={isQuizCard?ImageUrl + "/" + item.image: ImageUrl + "/" + item.thumbnail_image}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </RecommendedContainer>
      )}
    </React.Fragment>
  );
};

RecommendedEvents.propTypes = {
  RecommendedEventData: PropTypes.array,
  margin: PropTypes.string,
  isActivity: PropTypes.bool,
  history: PropTypes.object.isRequired,
  RecommendedEventDataLoading: PropTypes.bool,
  themesHeader: PropTypes.object,
  heading: PropTypes.string,
  isCoachEvent: PropTypes.bool,
  marginB: PropTypes.string,
  buttonName: PropTypes.string,
  isQuizCard: PropTypes.bool
};

const mapStateToProps = (state) => ({
  themesHeader: state.profileData.themesHeader
});

export default connect(mapStateToProps, null)((withTranslation())(RecommendedEvents));
